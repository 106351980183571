<template>
  <div class="customer-acquisition-finance">
    <div class="wrap-content">
      <div class="search-box">
        <div class="input">
          <div class="search-icon">
            <img :src="searchIcon" alt="" />
          </div>
          <span>请输入企业名称、企业地址</span>
        </div>
        <div class="btn-icon">
          <img :src="searchBtn" alt="" />
        </div>
      </div>
      <div id="container" ref="mapRef" @click.stop></div>
      <!-- 附近 -->
      <div class="vicinity-box">
        <div class="icon-box">
          <el-icon><Promotion /></el-icon>
          <span>附近客户</span>
        </div>
        <img :src="vicinityIcon" alt="" />
      </div>
      <!-- 底部tabbar -->
      <div class="footer">
        <tabbarBox :tabbarData="tabbarData"></tabbarBox>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import AMapLoader from "@amap/amap-jsapi-loader";
import tabbarBox from "../tabbar/index.vue";

// 默认标记点
const defaultMarkerList = [
  {
    position: [113.877539, 22.591168],
    icon: require("@/assets/images/imitatepage/customer-acquisition-finance/icon_new.png"),
  },
  {
    position: [113.883385, 22.575852],
    icon: require("@/assets/images/imitatepage/customer-acquisition-finance/icon_01.png"),
  },
  {
    position: [113.849317, 22.572574],
    icon: require("@/assets/images/imitatepage/customer-acquisition-finance/icon_02.png"),
  },
  {
    position: [113.870966, 22.558712],
    icon: require("@/assets/images/imitatepage/customer-acquisition-finance/icon_03.png"),
  },
  {
    position: [113.865549, 22.569218],
    icon: require("@/assets/images/imitatepage/customer-acquisition-finance/icon_new.png"),
  },
  {
    position: [113.862249, 22.588594],
    icon: require("@/assets/images/imitatepage/customer-acquisition-finance/icon_05.png"),
  },
  {
    position: [113.911566, 22.560722],
    icon: require("@/assets/images/imitatepage/customer-acquisition-finance/icon_04.png"),
  },
];

// 搜索图标
const searchIcon = require("@/assets/images/imitatepage/customer-acquisition-finance/search-icon.png");
// 搜索按钮图标
const searchBtn = require("@/assets/images/imitatepage/customer-acquisition-finance/search-btn-icon.png");
// 附近图标
const vicinityIcon = require("@/assets/images/imitatepage/customer-acquisition-finance/arrow-top.png");
// tabbar
const tabbarData = [
  {
    icon: require("@/assets/images/imitatepage/customer-acquisition-finance/tabbar-01.png"),
    text: "首页",
    color: "#53c3cd",
    bold: 600,
    isConvention: true,
  },
  {
    icon: require("@/assets/images/imitatepage/customer-acquisition-finance/tabbar-02.png"),
    text: "客户管理",
    color: "#a4a4a4",
    isConvention: true,
  },
  {
    icon: require("@/assets/images/imitatepage/customer-acquisition-finance/tabbar-03.png"),
    text: "预约管理",
    color: "#a4a4a4",
    isConvention: true,
  },
  {
    icon: require("@/assets/images/imitatepage/customer-acquisition-finance/tabbar-04.png"),
    text: "开户结果",
    color: "#a4a4a4",
    isConvention: true,
  },
];

// 地图ref
const mapRef = ref();
const initMap = () => {
  AMapLoader.load({
    key: "20c7b01acc2fb78e562ea0a1a5d725dc", //申请好的Web端开发者 Key，调用 load 时必填
    version: "2.0", //指定要加载的 JS API 的版本，缺省时默认为 1.4.15
  })
    .then((AMap) => {
      const map = new AMap.Map(mapRef.value, {
        zoom: 10, //地图级别
        center: [113.859607, 22.578652], //地图中心点
        mapStyle: "amap://styles/444f18dc1647441832c3bb94d1338071", //设置地图的显示样式
        viewMode: "2D", //设置地图模式
      });

      // const marker = new AMap.Marker({
      //   position: [113.859607, 22.578652],
      //   offset: new AMap.Pixel(-10, -10),
      // });

      // let marker = null;

      for (var i = 0; i < defaultMarkerList.length; i++) {
        let marker = new AMap.Marker({
          position: defaultMarkerList[i].position, //不同标记点的经纬度
          offset: new AMap.Pixel(-10, -10),
          map: map,
          icon: defaultMarkerList[i].icon, // 添加 Icon 图标 URL
        });
      }

      // map.add(marker);
    })
    .catch((e) => {
      console.error(e); //加载错误提示
    });
};

onMounted(() => {
  window._AMapSecurityConfig = {
    securityJsCode: "0bb43ddef1a2e931677e28a278955930",
  };
  initMap();
});
</script>

<style lang="scss" scoped>
.customer-acquisition-finance {
  position: relative;
  width: 100%;
  height: 100%;

  .wrap-content {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 40px;
    // padding: 20px;
    padding-bottom: 60px;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    justify-content: center;

    // 搜索
    .search-box {
      display: flex;
      justify-content: center;
      position: absolute;
      top: 50px;
      width: 100%;
      z-index: 2;
      .input {
        width: 300px;
        height: 28px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        background-color: #ffffff;
        font-size: 12px;
        padding-left: 7px;
        box-sizing: border-box;
        box-shadow: 1px 1px 6px 2px #d2d2d2;

        span {
          color: #c1c1c1;
        }

        .search-icon {
          margin-right: 15px;
          display: flex;
          align-items: center;
        }
      }

      .btn-icon {
        margin-left: 15px;
        margin-top: 2px;
      }
    }

    #container {
      width: 100%;
      height: 100%;
    }

    // 附近
    .vicinity-box {
      position: absolute;
      bottom: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 35px;
      background-color: rgb(92 92 92 / 30%);
      img {
        width: 15px;
        height: 17px;
      }

      .icon-box {
        position: absolute;
        font-size: 14px;
        left: 15px;
        color: white;
        display: flex;
        align-items: center;

        .el-icon {
          margin-right: 5px;
          font-size: 16px;
        }
      }
    }

    .footer {
      position: absolute;
      bottom: 0;
      width: 100%;
    }
  }

  // 地图logo

  :deep(.amap-logo) {
    display: none !important;
  }
  :deep(.amap-copyright) {
    display: none !important;
  }
}
</style>
